<template>
    <span @click="back" 
    style="cursor: pointer;">
        <span class="text-back mb-2"
              >
            <img class="arabic-arrow" src="@/assets/images/back.png"
                 alt=""
                 width="70px"
                 style="cursor: pointer;margin-bottom: -10px;">
        </span>
        <span class="mt-1 text-black font-weight-semibold"
              style="font-size: 14px;">{{ $t(text) }}</span>
    </span>
</template>
<script>

// import ManageCardActions from './ManageCardActions.vue';

export default {
    components: {

    },
    props: {
        text: {
            default: '',
            type: String
        },
        output: {
            default: false,
            type: Boolean
        },
    },

    data: () => ({

    }),
    mounted()
    {

    },

    methods: {
        back()
        {
            if (!this.output)
            {
                window.history.back()
            }
            else
            {
                this.$emit('back', true);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.text-back {
    margin-right: -8px;
}
.v-application.theme--light .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>
;