<template>
    <div class="auth-wrapper auth-v1"
         style="padding-bottom: 0px; padding-top: 0px">
        <div v-if="showAlert">
            <alert :message="alertMessage"
                   :type="alertType"></alert>
        </div>
        <div class="auth-inner main-container-width">
            <app-header></app-header>
            <v-row>
                <v-col cols="12"
                       sm="12"
                       md="12"
                       lg="12"
                       class="mx-auto">
                    <v-card>
                        <div class="mx-6 pt-4">
                            <BackAction :text="text"
                                        :output="output"
                                        @back="back" />
                        </div>
                        <v-row class="mx-10">
                            <v-col sm="12"
                                   md="12"
                                   lg="12">
                                <v-card flat
                                        class="full-min-height">
                                    <v-card-text class="pb-0"> </v-card-text>
                                    <v-form ref="loginForm"
                                            class="multi-col-validation"
                                            lazy-validation
                                            v-model="valid">
                                        <v-row>
                                            <v-col cols="12"
                                                   lg="5"
                                                   md="6"
                                                   sm="12">
                                                <div>
                                                    <p class="text-2xl font-weight-semibold text-black"
                                                       style="margin-bottom: 0">
                                                        {{ $t('View Card Pin') }}
                                                    </p>

                                                    <div style="margin: auto">
                                                        <div>
                                                            <v-container v-if="!showPin">
                                                                <p class="font-weight-semibold text-color-black mb-4 mt-8"
                                                                   style="font-size: 13px">
                                                                    {{
                                                                        $t(`Please enter 5-digits verification code sent to
                                                                    you over SMS`)
                                                                    }}
                                                                </p>
                                                                <v-row class="otp">
                                                                    <v-col cols="12"
                                                                           lg="8"
                                                                           md="8"
                                                                           sm="12"
                                                                           dir="ltr">
                                                                        <v-otp-input v-model="otp"
                                                                                     :rules="genericRules"
                                                                                     @input="onOtpInput"
                                                                                     @blur="onInputBlur"
                                                                                     ref="otpInput"
                                                                                     :hide-details="true"
                                                                                     onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                                                                     length="5"
                                                                                     type="number"></v-otp-input>
                                                                        <div v-if="otpError"
                                                                             style="font-size: 12px"
                                                                             class="error-message">
                                                                            {{ $t(otpErrorMessage) }}
                                                                        </div>
                                                                    </v-col>
                                                                </v-row>

                                                                <div class="mt-4">
                                                                    <timer :formattedTime="formattedTime"
                                                                           :selectedLanguage="selectedLanguage"
                                                                           @retry="sendOtpAgain"></timer>
                                                                </div>

                                                                <v-row class="mt-5">
                                                                    <v-col lg="6"
                                                                           md="6"
                                                                           sm="12">
                                                                        <v-btn color="primary"
                                                                               x-large
                                                                               :disabled="otp.length < 5"
                                                                               @click="viewCardPinCode(1)"
                                                                               block>
                                                                            {{ $t('View') }}
                                                                        </v-btn>
                                                                    </v-col>
                                                                    <v-col lg="6"
                                                                           md="6"
                                                                           sm="12">
                                                                        <v-btn class="text-blue"
                                                                               style="border: 1px solid #016fd0"
                                                                               @click="goBack"
                                                                               x-large
                                                                               block>
                                                                            {{ $t('Go Back') }}
                                                                        </v-btn>
                                                                    </v-col>

                                                                </v-row>
                                                            </v-container>

                                                            <v-container v-if="showPin">
                                                                <div class="mx-3 mt-8 security">
                                                                    <v-container>
                                                                        <p class="font-weight-semibold text-color-black"
                                                                           style="font-size: 14px">
                                                                            {{
                                                                                $t(`For your security, we do not show the
                                                                            full PIN number all together.The number will
                                                                            appear automatically in sequence.`)
                                                                            }}
                                                                        </p>
                                                                        <div class="d-flex">
                                                                            <v-row>
                                                                                <v-col lg="8"
                                                                                       dir="ltr">
                                                                                    <v-otp-input v-model="pin"
                                                                                                 readonly
                                                                                                 hide-details="auto"
                                                                                                 onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                                                                                 class="mb-1 pin"
                                                                                                 length="4"></v-otp-input>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </div>
                                                                    </v-container>
                                                                </div>
                                                                <v-row class="mt-5">
                                                                    <v-col lg="12"
                                                                           md="12"
                                                                           sm="12">
                                                                        <v-btn color="primary"
                                                                               x-large
                                                                               :disabled="seqNo != 4 && seqNo != 8"
                                                                               @click="viewCardPinCode(5)"
                                                                               block>
                                                                            {{ $t('View Pin Again') }}
                                                                        </v-btn>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-container>
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-col>
                                            <v-col class="divide">
                                                <v-divider class="my-2"
                                                           :vertical="true"></v-divider>
                                            </v-col>
                                            <v-col cols="12"
                                                   lg="6"
                                                   md="5"
                                                   sm="12">
                                                <div class="text-center"
                                                     style="padding-top: 38px">
                                                    <div class="secure-img">
                                                        <img src="@/assets/images/logos/secure.png"
                                                             alt="" />
                                                    </div>
                                                    <p class="font-weight-semibold text-color-black mb-2 mt-5">
                                                        {{ $t('Secure Account') }}
                                                    </p>
                                                    <p class="font-weight-semibold text--primary mb-2 mt-5 security-text"
                                                       style="margin: 0 auto; font-size: 13px">
                                                        {{
                                                            $t(`American Express has multiple security controls in place to
                                                        ensure your information is protected and safe.`)
                                                        }}
                                                    </p>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import timer from '@/@core/timer.vue'
import AppHeader from '../test/AppHeader.vue'
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import alert from '../alert.vue'
import moment from 'moment'
import { DeviceUUID } from 'device-uuid'
import sharedServices from '@/services/shared-services'
import soapServices from '@/services/soap-services'
import store from '@/store'
import BackAction from '@/@core/BackAction.vue'
import soapErrorMessages from '@/@core/utils/soap-error-messages';

import { getAnalytics, logEvent } from "firebase/analytics";

export default {
    components: {
        AppBarI18n,
        AppBarThemeSwitcher,
        alert,
        BackAction,
        timer,
        AppHeader
    },
    data: () => ({
        analytics: getAnalytics(),
        seqNo: 1,
        valid: false,
        showTimer: false,
        time: 60,
        timerId: null,
        showPin: false,
        cardDetail: null,
        alertMessage: '',
        alertType: 'error',
        showAlert: false,
        otp: '',
        otpError: false,
        otpErrorMessage: '',
        pin: '',
        currentPin: '',
        selectedLanguage: 'en',
        text: 'Back to Card Details',
        output: false,
        genericRules: [v => !!v || 'Field is required'],
        selectedCard: {},

        correlationID: Math.floor(1000 + Math.random() * 9000).toString(),
        messageStamp: moment(new Date()).format('DD/MM/yyyy HH:mm:ss'),
    }),
    mounted()
    {
        let lang = localStorage.getItem('language')
        if (lang)
        {
            this.selectedLanguage = localStorage.getItem('language')
        }
        this.cardDetail = store.get('card/details')
        this.$route.params.cardType == 'primary'
            ? (this.cardDetail = store.get('card/details'))
            : (this.cardDetail = store.get('card/supplementory'))
        this.sendOtpAgain()
        window.scrollTo(0, 0)
    },
    created()
    {
        store.subscribe(mutation =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
            }
        })
    },
    computed: {
        formattedTime()
        {
            const minutes = Math.floor(this.time / 60);
            const seconds = this.time % 60;
            return `${seconds.toString().padStart(2, '0')}`;
        },
        mr3Class()
        {
            if (this.selectedLanguage === 'ar')
            {
                return 'ml-3'
            } else
            {
                return 'mr-3'
            }
        },
    },
    methods: {
        onOtpInput()
        {
            if (this.otp.length !== 5)
            {
                this.otpError = true
                this.otpErrorMessage = '5 digits code is required.';
                const inputElement = this.$refs.otpInput.$el.querySelector('input');
                if (inputElement)
                {
                    inputElement.focus(); // Move focus back into the input
                }
            } else
            {
                this.otpError = false
            }
        },
        onInputBlur()
        {
            if (!this.otp.length)
            {
                this.otpError = true
                this.otpErrorMessage = '5 digits code is required.'
            }
        },
        back()
        {
            this.showPin = false;
            this.output = false
        },
        goBack()
        {
            window.history.back()
        },
        sendOtpAgain()
        {

            let obj = {
                correlationID: Math.floor(1000 + Math.random() * 9000).toString(),
                messageStamp: moment(new Date()).format('DD/MM/yyyy HH:mm:ss'),
                sysId: new DeviceUUID().get(),
                sysAuth: store.get('requestKeys/accessToken'),
                requestorID: localStorage.getItem('userName'),
                idSeed: sharedServices.getIdSeed(),
                custSerNo: store.get('requestKeys/ClientCode'),
                cardSserno: this.cardDetail.primeCardSerno,
            }

            soapServices.getOtp(obj).then(soapResp =>
            {
                if (soapResp?.RequestAuthCodeResponse?.RequestAuthCodeResult?.Result?.ErrorCode == '000')
                {
                    let alertMessage = soapResp.RequestAuthCodeResponse.RequestAuthCodeResult.Body.ResponseMsg
                    let errorCode = soapResp.RequestAuthCodeResponse.RequestAuthCodeResult.Result.ErrorCode
                    this.alertMessage = soapErrorMessages.get(errorCode, alertMessage)
                    this.alertType = 'success'
                    this.showAlert = true
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                    this.showTimer = true
                    this.timerId = setInterval(() =>
                    {
                        this.time -= 1
                        if (this.time <= 0)
                        {
                            clearInterval(this.timerId)
                            this.showTimer = false
                            this.time = 60
                            this.timerId = null
                        }
                    }, 1000)
                } else
                {
                    let alertMessage = soapResp.RequestAuthCodeResponse.RequestAuthCodeResult.Resp.Result.ErrorDesc
                    let errorCode = soapResp.RequestAuthCodeResponse.RequestAuthCodeResult.Result.ErrorCode
                    this.alertMessage = soapErrorMessages.get(errorCode, alertMessage)
                    this.alertType = 'error'
                    this.showAlert = true
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                }
            })
        },
        viewCardPinCode(val)
        {
            this.output = true
            if (this.seqNo == 8)
            {
                ; (this.alertMessage =
                    this.selectedLanguage == 'en'
                        ? 'You have exceeded the number of attempts. You can view your PIN again by starting over.'
                        : 'لقد تجاوزت عدد المحاولات المسموح بها، يمكنك مشاهدة الرقم السري مرة أخرى من خلال البدء من جديد'),
                    (this.alertType = 'error')
                this.showAlert = true
                setTimeout(() =>
                {
                    this.showAlert = false
                }, 5000)
                return
            }
            let obj = {
                correlationID: this.correlationID,
                messageStamp: this.messageStamp,
                sysId: new DeviceUUID().get(),
                sysAuth: store.get('requestKeys/accessToken'),
                requestorID: localStorage.getItem('userName'),
                idSeed: sharedServices.getIdSeed(),
                custSerNo: store.get('requestKeys/ClientCode'),
                cardSserno: this.cardDetail.primeCardSerno,
                authCode: this.otp,
                seqNo: val,
            }

            soapServices.viewCardPinCode(obj).then(soapResp =>
            {
                if (soapResp?.ViewCashPinResponse.ViewCashPinResult?.Resp?.Result?.ErrorCode != '000')
                {
                    let alertMessage = soapResp.ViewCashPinResponse.ViewCashPinResult.Resp.Result.ErrorDesc
                    let errorCode = soapResp.ViewCashPinResponse.ViewCashPinResult.Resp.Result.ErrorCode
                    this.alertMessage = soapErrorMessages.get(errorCode, alertMessage)
                    this.alertType = 'error'
                    this.showAlert = true
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)


                    logEvent(this.analytics, 'AMEX_CI_VIEW_CARD_PIN_FAILURE', {
                        content_type: 'userName',
                        content_id: store.get('requestKeys/userName')
                    })

                }
                if (soapResp?.ViewCashPinResponse.ViewCashPinResult?.Resp?.Result?.ErrorCode == '000')
                {
                    logEvent(this.analytics, 'AMEX_CI_VIEW_CARD_PIN_SUCCESS', {
                        content_type: 'userName',
                        content_id: store.get('requestKeys/userName')
                    })

                    this.showPin = true
                    let pinBlock = soapResp?.ViewCashPinResponse.ViewCashPinResult?.Resp?.Body?.PinBlock
                    setTimeout(() =>
                    {
                        this.getAutoMationPin(val, pinBlock)
                    }, 1000)
                }
            })
        },
        getAutoMationPin(val, pinBlock)
        {
            if (val == 1 || val == 5)
            {
                this.pin = pinBlock.toString() + '***'
                this.seqNo = val
            }
            if (val == 2 || val == 6)
            {
                this.pin = '*' + pinBlock.toString() + '**'
                this.seqNo = val
            }
            if (val == 3 || val == 7)
            {
                this.pin = '**' + pinBlock.toString() + '*'
                this.seqNo = val
            }
            if (val == 4 || val == 8)
            {
                this.pin = '***' + pinBlock.toString()

                setTimeout(() =>
                {
                    this.pin = '****'
                    this.seqNo = val
                }, 3000)
            }
            if (val != 4 && val != 8)
            {
                setTimeout(() =>
                {
                    this.viewCardPinCode(Number(val) + 1)
                }, 2000)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.main-width {
    width: 502px;
}

// background: #D9D9D9;

.pin-btn {
    width: inherit;
    height: 54px !important;
    margin-top: 4px;
}

.refersh {
    background: #d9d9d9;
    padding: 11px 9px 9px 9px;
    border-radius: 5px;
    cursor: pointer;
}

.v-btn--round {
    border-radius: 0px !important;
}

.otp {
    margin-bottom: -17px;
}

::v-deep .v-text-field input {
    flex: 1 1 auto;
    line-height: 20px;
    padding: 0px 0 8px;
    max-width: 100%;
    min-width: 0px;
    width: 100%;
}

::v-deep .pin>.v-text-field input {
    padding: 15px 0 11px !important;
    font-size: 21px;
    font-weight: 700;
    color: #016fd0;
    padding: top;
}

::v-deep .v-input__control>.v-input__slot {
    align-items: stretch;
    min-height: 48px;
    /* padding: 14px 0px; */
}

@media screen and (max-width: 630px) {
    .main-width {
        width: 100% !important;
    }
}

.secure-img {
    background: #e2f1fd;
    width: 123px;
    height: 123px;
    padding-top: 33px;
    border-radius: 79px;
    margin: 0 auto;
}

.otp-input {
    width: 290px;
}

.security-text {
    width: 260px;
}

.divide {
    height: 235px;
    position: relative;
    left: 74px;
    right: 74px;
    top: 48px;
}

::v-deep .v-text-field input {
    padding: 7px 0 8px !important;
}

@media screen and (max-width: 950px) {
    .divide {
        display: none;
    }
}

@media screen and (max-width: 399px) {
    .text-color-black {
        font-size: 14px !important;
    }
}

@media screen and (max-width: 500px) {
    .otp-input {
        width: 100% !important;
    }

    .security-text {
        width: 100%;
    }
}

.confirmation {
    width: 40vw;
    margin: auto;
}

@media screen and (max-width: 699px) {
    .text-black {
        font-size: 17px !important;
    }

    .text-color-black {
        font-size: 14px !important;
    }

    .confirmation {
        width: 80vw;
        margin: auto;
    }
}

@media screen and (max-width: 400px) {
    .v-stepper__content {
        padding: 24px 0px !important;
    }
}
</style>
